<template>
<div class="row">
     <div class="col-md-2 col-2" id="tab-container">
        <ul class="nav flex-column">
            <li class="nav-item" v-bind:class="{'selected':showThreeD==false}">
                <a class="nav-link"   @click="showThreeD=false">{{$t("detail.image")}}</a>
            </li>
            <li class="nav-item" v-bind:class="{'selected':showThreeD==true}">
                <a class="nav-link"  @click="showThreeD=true">{{$t("detail.3d")}}</a>
            </li>
        </ul>
    </div>
    <div class="col-md-10 col-9 coverImageRow imageView px-0" v-if="showThreeD==true">
        <threeD :threeDFile="threeDFile" :width="threeDWidth" />
    </div>
    <div class="col-md-10 col-9 coverImageRow imageView px-0 rounded-3"  v-if="showThreeD==false">
        <a :href="`${curCoverImage}-wm5`" target="_blank">
            <img class="coverImage rounded-3" :src="`${curCoverImage}-wm5?imageView2/1/w/600/h/600/q/100/interlace/1`" />
        </a>
        <div class="justify-content-md-center">
            <ul class="thumbs">
                <li v-for="(image, index) in images" v-bind:key="index" >
                    <img v-bind:class="{curCoverImage:image==curCoverImage}" class="detailImgs " 
                        @click="changeCurCoverImage(image)" :src="`${image}-wm5?imageView2/1/w/150/h/150/q/100/interlace/1`" />
                </li>
            </ul>
        </div>
    </div>
</div>
   
    
    
</template>

<script>
import threeD from '../../components/product/3d'
const ThreeDPreviewImage = "https://image.tyestore.com/Fot9PK3kl4rhkH3Dej03VYoZMMK1"

export default({
    name:"ProductImagesGallery",
    data(){
        return {
            curCoverImage:this.images[0],
            showThreeD: false,
            threeDPreviewImage:ThreeDPreviewImage
        }
    },
    components: {threeD},
    created(){
        this.$watch('images', ()=>{
            this.curCoverImage = this.images[0];
            this.showThreeD = false;
        },{immediate: true})
        this.$watch('threeDFile', ()=>{
        },{immediate:true})
        this.$watch('curCoverImage', ()=>{
        },{immediate:true})
    },
    props: {
        images: Array,
        threeDFile: String
    },
    methods:{
        changeCurCoverImage(image) {
            this.curCoverImage = image
        }

    }
})
</script>

<style scoped>
    .imageView{
        border: 1px solid rgba(0, 0, 0, 0.125);
    }
    #tab-container ul {
        list-style: none;
        text-align: center;
        padding: 0 0 0 0px;
    }
    #tab-container ul li {
        border-bottom: none;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        color: black;
    }
    #tab-container ul li.selected {
        border-bottom: none;
        background-color: #fff;
        /* border-left: 8px solid #006699; */
        background: #c5c5c5;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    #tab-container ul li {
        margin: 100px -35px 0px 0px;
        transform: rotate(
    -90deg
    );
        -webkit-transform: rotate(
    -90deg
    );
        -moz-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
    }
    #tab-container ul li.selected a {
        color: #fff;
    };
    a {
        color: #000;
        text-decoration: none;
        -webkit-transition: all 0.2s ease-in 0s;
        transition: all 0.2s ease-in 0s;
    }
    #tab-container ul li a, #tab-container ul li a:visited {
        text-decoration: none;
        display: block;
        color:black;
        padding: 10px 5px;
        text-align: center;
        line-height: 1em;
        /* color: #d7e2eb; */
    }
</style>