<template ref="product">
    <div class="row">
        <div class="col-md-6 relay-item" v-for="(product,index) in songle" v-bind:key="index" >
            <h4>{{product.serials}}</h4>
            <div v-for="(attrs,idx) in product.attributes" v-bind:key="idx">
                <span class="attrTitle">{{idx}}</span><span class="attr" v-for="(a,i) in attrs" v-bind:key="i">{{a}}</span>
            </div>
            <div class="row">
                <span 
                v-bind:class="{hasPrice:product.prices !== undefined && product.prices[p] != undefined,hasTBPrice:product.tbPrices !== undefined && product.tbPrices[p] != undefined}" 
                class="col-md-12 relay-product" v-for="(p,index) in list[product.serials]" v-bind:key="index">{{p}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import SongleInfo from "../../../src/assets/products/songlerelay.json"
export default {
    name: 'Songle',
    data(){
        return {
            // "songle": this.buildInfo()
            "songle": SongleInfo,
            "list":this.getList(),
        }
    },
    methods:{
        getList(){
            var obj = {}
            for(var p in SongleInfo){
                let source = [SongleInfo[p]["serials"]]
                console.log(source);
                for(var attr in SongleInfo[p].attributes){
                    var ret = this.buildInfo(source, SongleInfo[p].attributes[attr])
                    source = ret
                    
                }
                obj[SongleInfo[p]["serials"]] = source 
            }
            return obj
        },
        buildInfo(source, combinedAttr){
            let r = []
            for(var s in source){
                for(var c in combinedAttr){
                    if(combinedAttr[c] == ""){
                        continue;
                    }
                    r.push(source[s]+"-"+combinedAttr[c])
                }
            }
            return r
        }
    }
}
</script>

<style>
.relay-item{
    border:1px solid gray
}
.attrTitle{
    border:1px solid blue;
}
/* .attr{
    border:1px solid red;
} */
.relay-product{
    border:1px solid green;
}
.hasPrice{
    background:red;
}
.hasTBPrice{
    background: yellow;
}
</style>