<template>
        <div class="row footer pt-4">
            <p>Copyright © 2021  {{$t('header.taiyuan')}}  https://www.tyestore.com All Rights Reserved.</p>
            <p><a href="https://beian.miit.gov.cn/">粤ICP备2021130053号-1</a></p>
        </div>
</template>


<script>
export default {
  name: "Footer",
  props: {
    msg: String
  }
}
</script>
<style>
.footer{
    background-color:#f9f9f9;
    font-size:small;
}
</style>
