<template>
    <span id="paginate" class="page-bar">
        <ul>
            <li v-if="currentPage>1"><router-link :to="`?page=${currentPage-1}`">{{$t("paginate.prev")}}</router-link></li>
            <li v-if="currentPage==1"><a class="banclick">{{$t("paginate.prev")}}</a></li>
            <li class="pageIndex" v-for="(page, index) in pages" v-bind:key="index" v-bind:class="{'active': currentPage==page}">
                <router-link :to="`?page=${page}`">{{page}}</router-link>
            </li>
            <li v-if="currentPage!=totalPage"><router-link :to="`?page=${currentPage+1}`">{{$t("paginate.next")}}</router-link></li>
            <li v-if="currentPage==totalPage"><a class="banclick">{{$t("paginate.next")}}</a></li>
            <li><a>{{$t("paginate.total")}}<i>{{totalPage}}</i>{{$t("paginate.page")}}</a></li>
        </ul>    
    </span>    
</template>

<script>
export default {
    name: 'Paginate',
    data() {
        return {
            pages: [1]
        }
    },
    props: {
        totalPage: Number,
        displayPage: Number,
        currentPage: Number
    },
    created(){
        for(var i = 1; i < this.totalPage; i++){
            this.pages.push(i)
        }
    },
    watch: {
        currentPage() {
            this.getIndexes()
        },
        totalPage(){
            this.getIndexes()
        },
    },
    methods:{
        getIndexes(){
            var left = 1
            var right = this.totalPage
            if (this.totalPage >= 5) {
                if(this.currentPage > 3 && this.currentPage < this.totalPage - 2) {
                    left = this.currentPage - 2
                    right = this.currentPage + 2
                }else {
                    if(this.currentPage <= 3){
                        left = 1
                        right = 5
                    }else {
                        right = this.totalPage
                        left = this.totalPage - 4
                    }
                }
            }
            this.pages = []
            while(left <= right) {
                this.pages.push(left)
                left++
            }
        }
    },
    updated() {
        this.$nextTick(function () {
            //仅在渲染整个视图之后运行的代码
        })
    }
}
</script>
<style>
    .page-bar{
        margin:40px auto;
        /* margin-top: 150px; */
    }
    ul, li {
        margin:0px;
        padding:0px;
    }
    li {
        list-style: none;
    }
    .page-bar li:first-child>a {
        margin-left: 0px
    }
    .page-bar a{
        border: 1px solid #ddd;
        text-decoration: none;
        position: relative;
        float: left;
        padding: 6px 12px;
        margin-left: -1px;
        line-height: 1.42857143;
        color: #5D6062;
        cursor: pointer;
        margin-right: 20px;
    }
    .page-bar a:hover{
        background-color: #eee;
    }
    .page-bar a.banclick{
        cursor:not-allowed;
    }
    .page-bar .active a{
        color: #fff;
        cursor: default;
        background-color: #E96463;
        border-color: #E96463;
    }
    .page-bar i{
        font-style:normal;
        color: #d44950;
        margin: 0px 4px;
        font-size: 12px;
    }
</style>