<template>
    <div class="image-wrapper mb-3">
        <div @click="jump" class="card">
            <div class="image-wrapper">
                <img class="image rounded-3" :src="`${product.coverImage}-wm5?imageView2/1/w/400/h/400/q/100/interlace/1`"  />
            </div>
            <div class="card-body">
                <div class="product-name">{{product.name}}</div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'ProductListItem',
        data(){
            return {}
        },
        props: {
            product: Object, 
        },
        created(){
            // console.log("this route" + this.$route)
        },
        methods:{
            jump(){
                this.$router.push({path:"/"+this.$route.params.lang+"/products/"+this.product.id})
            }
        }
    }
</script>

<style lang="scss"  scoped >
.product-name {
    text-decoration: none !important;
    color: rgb(17, 17, 17);
    font-weight: 500;
    font-size: 16px;
    text-align:left;
}
.image-wrapper{
    flex-shrink: 0;
    min-height:150px;
    background:#f6f6f6;
    border-radius: 6px;
    /* overflow: hidden; */
}
.image{
    width: 100%;
    height: 100%;
    /* min-height:250px; */
}

.list-item{
    height:120px;

   
}

</style>