<template>
    <div class="container">
        <div class="row px-md-5 mb-3 pt-4 px-1">
            <Navigation :productName="this.$route.params.category" :category="``"  />
        </div>
        <div class="row px-md-5">
            <div class="col-md-3 col-sm-12">
                <Category />
            </div>
            <div class="col-md-8 col-sm-12">
                <div v-if="isLoading==true" class="d-flex justify-content-center">
                    <div class="spinner-border m-5" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
                <div class="row" v-if="isLoading==false">
                    <h3 v-if="isLoading==false && products.length==0">{{$t("search.notFound")}}</h3>
                    <div class="col-md-3 col-6 col-sm-10 list-item" v-for="product in products" :key="product.id" >
                        <ProductListItem :product="product" />
                    </div>
                </div>
                <div class="row text-center">
                    <Paginate :totalPage="totalPage" :currentPage="currentPage" :displayPage=10 />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ProductListItem from '../../components/product/ListItem'

import mixin from '../../common/mixin/mixin'
import Paginate from '../../components/common/Paginate'
import Category from '../../components/product/Category'
import Navigation from '../../components/common/Navigation'

export default {
    name: 'List',
    mixins: [mixin],
    components: {
        ProductListItem,
        Paginate,
        Category,
        Navigation
    },
    watch: {
        $route(to, from) {
            this.loadData();
            console.log(to, from);
        }
    },
    data() {
        return {
            isLoading: true,
            totalPage: 1,
            currentPage:1,
            category: "",
            products: []
        }
    },
    created(){
        console.log(this.$route.params.category)
        if(this.$route.query.page != undefined){
            this.currentPage = parseInt(this.$route.query.page)
        }
        if(this.$route.params.category != undefined){
            this.category = this.$route.params.category
        }
        console.log("created load data")
        this.loadData(); //加载评价
        // this.log(this.$data)
        this.$watch('totalPage', ()=>{
        },{deep: true})
        this.$watch('currentPage', ()=>{
        },{deep: true})
        this.$watch('category', ()=>{
        },{deep: true})
    },
    // updated(){
    //     // console.log(this.$route.query)
    //     // console.log(this.$route.params.category)
    //     var isUpdate = false
    //     if(this.category != this.$route.params.category){
    //         this.category = this.$route.params.category
    //         isUpdate = true
    //     }
    //     if(this.$route.query.page != undefined 
    //         && this.currentPage != this.$route.query.page
    //         ){
    //         // console.log(this.$route.query.page, this.currentPage)
    //         this.currentPage = parseInt(this.$route.query.page)
    //         this.category = this.$route.query.category
    //         // this.loadData();
    //         isUpdate = true
    //     }
    //     if(isUpdate == true){
    //         // console.log("updated load data")
    //         this.loadData();
    //     }
        
        
    // },
    
    props: {
        list: Array
    },
    methods: {
        async loadData(){
            this.isLoading = true
            // console.log(this)
            var page = this.$route.query.page != undefined ? this.$route.query.page : 1
            var query = {
                page: page,
                pageSize:12,
            }
            if(this.$route.params.category != undefined){
                query["category"] = this.$route.params.category
            }
            // console.log(query)
            const res = await this.$request('store/products', query)
            // console.log(res.data.data)
            // if(res.data.data.list.length === 0){
            //     return;
            // }

            const data = res.data.data.list;
            for(var i = 0 ; i < data.length; i++) {
                if(data[i]["coverImage"] != undefined) {
                    data[i]["coverImage"] = "https://image.tyestore.com/"+data[i]["coverImage"]
                }else {
                    data[i]["coverImage"] = "https://image.tyestore.com/FvyFkzEnbB3j7Xf9uO7rOUAKaGEj"
                }
            }
            this.products = data;
            this.totalPage = parseInt(res.data.data.total / 12) + 1;
            this.currentPage = res.data.data.currentPage;
            this.isLoading = false;
            // this.$forceUpdate(()=>{

            // })
            // this.$nextTick(()=>{
            //     // this.calcAnchor();//计算锚点参数
            // })
            //添加浏览历史
            // this.addProductHistory();
        },
    }
}
</script>

<style >
    .fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
