<template>
    <div class="row row-cols-2 row-cols-md-4 g-3 mx-0 p-0 pb-3">
    <div class="col" v-for="(category, index) in categories" v-bind:key="index">
        <router-link :to="category.link">
            <div class="card">
            <img :src="`${category.src}-wm5?imageView2/1/w/400/h/400/q/100`" class="card-img-top" alt="...">
            <div class="card-body">
                <h5 class="card-title">{{category.name}}</h5>
                <p class="card-text">{{category.desc}}</p>
            </div>
            </div>
        </router-link>
    </div>
    </div>
</template>
<script>
export default {
    name:"hotsale",
    watch:{
        $route(to,from){
            if(to.params.lang != from.params.lang){
                this.categories = this.getCategories();
            }
        }
    },
    data(){
        return {
            categories: this.getCategories()
        }
    },
    methods:{
        getCategories(){
            return [
                {
                    "src":"https://image.tyestore.com/FsozgYiB9v24LHeUjWD1ABQJiZ1Z",
                    "name":this.$t("index.cbb60four"),
                    "desc":this.$t("index.motorRunCap"),
                    "link":"/"+this.$route.params.lang+"/products/categories/CBB60"
                },
                {
                    "src":"https://image.tyestore.com/FhxdqumTFqlLXRjou2TR239fKqjt",
                    "name":this.$t("index.cbb60wire"),
                    "desc":this.$t("index.motorRunCap"),
                    "link":"/"+this.$route.params.lang+"/products/categories/CBB60"
                },
                {
                    "src":"https://image.tyestore.com/Fu9I1v1mrEALlwa4Hrr7EXO-64Jx",
                    "name":this.$t("index.cbb60screw"),
                    "desc":this.$t("index.motorRunCap"),
                    "link":"/"+this.$route.params.lang+"/products/categories/CBB60"
                },
                {
                    "src":"https://image.tyestore.com/Fu4JSV3CYrwyFsBYGmMlqA8c7pM9",
                    "name":this.$t("index.cbb61four"),
                    "desc":this.$t("index.fanCap"),
                    "link":"/"+this.$route.params.lang+"/products/categories/CBB61"
                },
                {
                    "src":"https://image.tyestore.com/FoUAZ4964KxGpaorvZ3drPITqIrf",
                    "name":this.$t("index.cbb61wire"),
                    "desc":this.$t("index.fanCap"),
                    "link":"/"+this.$route.params.lang+"/products/categories/CBB61"
                },
                {
                    "src":"https://image.tyestore.com/Fj28ua1X9znTVcjqmJzbHVm4A1Ac",
                    "name":this.$t("index.cbb61needle"),
                    "desc":this.$t("index.fanCap"),
                    "link":"/"+this.$route.params.lang+"/products/categories/CBB61"
                },
                {
                    "src":"https://image.tyestore.com/Fs8Qgfy2Kk7vTuWWha28AxhkZiIh",
                    "name":this.$t("index.cbb61two"),
                    "desc":this.$t("index.fanCap"),
                    "link":"/"+this.$route.params.lang+"/products/categories/CBB61"
                },
                {
                    "src":"https://image.tyestore.com/FgtD7NzyBqPKIo3m5L6W0hkAeC8L",
                    "name":this.$t("index.cbb65withoutscrew"),
                    "desc":this.$t("index.airCap"),
                    "link":"/"+this.$route.params.lang+"/products/categories/CBB65"
                },
                {
                    "src":"https://image.tyestore.com/Fu3d9pbGX656bj6d8B4hsbnvNo8d",
                    "name":this.$t("index.cbb65withscrew"),
                    "desc":this.$t("index.airCap"),
                    "link":"/"+this.$route.params.lang+"/products/categories/CBB65"
                },
                {
                    "src":"https://image.tyestore.com/Fr8mlOn3yVlkHiwzvTuabZujM1BD",
                    "name":this.$t("index.cd60330v"),
                    "desc":this.$t("index.motorStartCap"),
                    "link":"/"+this.$route.params.lang+"/products/categories/CD60"
                },
                {
                    "src":"https://image.tyestore.com/Fs9l8HJt39haa-q2Q5J-QOcJumnB",
                    "name":this.$t("index.cd60450v"),
                    "desc":this.$t("index.motorStartCap"),
                    "link":"/"+this.$route.params.lang+"/products/categories/CD60 300V"
                },
                {
                    "src":"https://image.tyestore.com/FjxEpn82CLCZbBFrK5HcwLmJvqfR",
                    "name":this.$t("index.ch85"),
                    "desc":this.$t("index.microCap"),
                    "link":"/"+this.$route.params.lang+"/products/categories/CH85"
                }
            ]
        }
    }
}
</script>
