<template>
    <div class="header pt-4">
        <div class="text-left float-left navbar-light">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>
        <div class="position-absolute" style="left:80%;width:0px">
            <div class="btn-group" role="group" aria-label="Basic example">
                <button @click="switchLang('en')" type="button" :class="this.$route.params.lang=='en'? 'btn-secondary btn-light-secondary':'btn-light btn-outline-secondary'" class="btn btn-sm ">
                    EN
                </button>
                <button @click="switchLang('zh')" type="button" :class="this.$route.params.lang=='zh'? 'btn-secondary btn-light-secondary':'btn-light btn-outline-secondary'" class="btn btn-sm ">
                    中
                </button>
            </div>
        </div>
        
        <div class="row">
            <div class="offset-md-1 col-md-3 col-sm-12 col-xs-12">
                
                <div class="row ">
                    <div class="offset-sm-3 offset-xs-3 offset-3 col-9 offset-md-1 col-md-12 my-3">
                        <div class="row">
                            <div class="col-sm-4 col-xs-4 col-4">
                                <img class="logo" src="https://image.tyestore.com/FiyVuBkn1-URLWa41yCbamZofo9t"/>
                            </div>
                            <div class="col-sm-8 col-xs-8 col-8 text-left align-self-center" v-if="this.$route.params.lang=='en'" >
                                <h6 class="en-shopname">TaiYuan<br/>Electronics<br/>Store</h6>
                            </div>
                            <div class="col-sm-8 col-xs-8 col-8 text-left align-self-center" v-if="this.$route.params.lang=='zh'" >
                                <h4 class="zh-shopname">泰源电子</h4>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
                
            </div>

            <div class="col-md-6 offset-md-1 ">
                 <div class="row">
                    <div class="col-md-8 col-sm-12 col-12 px-4 offset-md-1">
                        <form @submit="searchProducts" action="" class="d-flex" >
                            <div class="input-group ">
                                <input type="text" v-model="search" class="form-control me-2"/>
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary " @click="searchProducts"  type="button" id="button-addon2">{{ $t("header.search") }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                    <div class="container-fluid">
                        
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav menu me-auto mb-2 mb-lg-0">
                                <li class="nav-item px-4 mt-3"><router-link class="nav-link" :to="`/${this.$route.params.lang}`">{{ $t("header.index") }}</router-link></li>
                                <li class="nav-item px-4 mt-3"><router-link class="nav-link" :to="`/${this.$route.params.lang}/products`">{{ $t("header.list") }}</router-link></li>
                                <li class="nav-item px-4 mt-3"><router-link class="nav-link" :to="`/${this.$route.params.lang}/about`">{{ $t("header.about") }}</router-link></li>
                                <li class="nav-item px-4 mt-3"><router-link class="nav-link" :to="`/${this.$route.params.lang}/contact`">{{ $t("header.contact") }}</router-link></li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <!-- <div class="row mt-1">
                    <div class="col-md-10 offset-md-2">
                        <ul class="menu ">
                            <li ><router-link :to="`/${this.$route.params.lang}`">{{ $t("header.index") }}</router-link></li>
                            <li ><router-link :to="`/${this.$route.params.lang}/products`">{{ $t("header.list") }}</router-link></li>
                            <li ><router-link :to="`/${this.$route.params.lang}/about`">{{ $t("header.about") }}</router-link></li>
                            <li ><router-link :to="`/${this.$route.params.lang}/contact`">{{ $t("header.contact") }}</router-link></li>
                        </ul>
                    </div>
                </div> -->
            </div>
        </div>
        
    </div>
</template>


<script>
export default {
    name: "Header",
    data(){
        return {
            search:''
        }
    },
    created(){
        console.log(this.$route)
    },
    props: {
        msg: String
    },
    methods:{
        searchProducts(e){
            console.log(this)
            this.$router.push({ path: `/${this.$route.params.lang}/search`, query: { s: this.search }})
            e.preventDefault();
        },
        switchLang(lang){
            // alert(lang)
            // this.$route.params.lang = lang
            // console.log(this.$route)
            var p = this.$route.path.replace(this.$route.params.lang, lang)
            this.$router.push({path:p, query: this.$route.query})
        }   
    }
}
</script>
<style >
    .header{
        background-color:#f9f9f9;
    }
   
    ul, ol {
        margin: 0;
        padding: 0;
        list-style: none;
    }
     ul.menu > li > a {
        font-family: "Source Sans Pro";
        text-transform: uppercase;
        font-style: normal;
        color: #0a0a0a;
        font-size: 15px;
        font-display: swap;
        text-decoration: none;
    }
    li {
        list-style: none;
        display:inline-block;
        padding: 5px 30px;
    }
    .nav-item {
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: var(--space-sm);
    }
    .nav-link{
        font-weight: 500;
        color: var(--text-dark);
        text-decoration: none;
        position: relative;
        padding: 0 var(--space-sm);
        margin-bottom: var(--space-sm);
        text-align: center;
    }
    ul.menu {
        padding: 0px;
        margin:0px;
    }

    .logo{
        /* width:300px; */
        height:80px;
    }
    .en-shopname{
        font-family: monospace;
        font-weight: 900;
        color: #5699c1;
        padding:0px;
        margin-left: -20px;
        font-size: 19px;
    }
    .zh-shopname{
        font-family: serif;
        font-weight: 900;
        font-size:24px;
        color: #5699c1;
        padding:0px;
        margin-left: -14px;
    }
    
    button.navbar-toggler {
        background: transparent;
        display: inline-block;
        width: 50px;
        height: 50px;
        border: none;
        position: absolute;
        border-radius: 3px;
        top: var(--space-sm);
        left: var(--space-sm);
    }
    @media (min-width: 992px){
        .navbar-light .navbar-toggler {
            display: none;
        }
    }
</style>
