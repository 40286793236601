<template ref="product">
    <div class="row">
        <div class="col-md-3 relay-item" v-for="(product,index) in list" v-bind:key="index" >
            <h4>{{index}}</h4>
            <a :href="product.pdf" >
            <img class="productImage" :src="product.img" /> <br/>
            <!-- <img class="detailImage" :src="product.detail"/> -->
            </a>
        </div>
    </div>
</template>

<script>
import YonglinInfo from "../../../src/assets/products/yonglinrelay.json"
export default {
    name: 'Yonglin',
    data(){
        return {
            // "songle": this.buildInfo()
            // "yonglin": YonglinInfo,
            "list":this.getList(),
        }
    },
    methods:{
        getList(){
            var obj = {}
            for(var p in YonglinInfo){
                // obj[p] = {"img": "/static/images/yonglin/sensor/"+p+"/product.png", "detail":"/static/images/yonglin/sensor/"+p+"/final.jpg","pdf":"/static/images/yonglin/sensor/"+p+"/"+p+".pdf"}; 
                obj[p] = {"img": "/static/images/yonglin/relayer/"+p+"/product.png", "detail":"/static/images/sensor/relayer/"+p+"/p-000002.png_cut.png","pdf":"/static/images/yonglin/relayer/"+p+"/"+p+".pdf"}; 
                // obj[p] = "/static/images/relay/YL-S100Y/YL-S100Y.jpg"; 
            }
            return obj
        },
        // buildInfo(source, combinedAttr){
        //     let r = []
        //     for(var s in source){
        //         for(var c in combinedAttr){
        //             if(combinedAttr[c] == ""){
        //                 continue;
        //             }
        //             r.push(source[s]+"-"+combinedAttr[c])
        //         }
        //     }
        //     return r
        // }
    }
}
</script>



<style>
.relay-item{
    border:1px solid gray
}
.attrTitle{
    border:1px solid blue;
}
/* .attr{
    border:1px solid red;
} */
.relay-product{
    border:1px solid green;
}
.hasPrice{
    background:red;
}
.hasTBPrice{
    background: yellow;
}
.productImage{
    width:100%;

}
.detailImage{
    width:100%;
}
</style>