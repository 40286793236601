import { createRouter, createWebHistory } from 'vue-router'
import ProductList from './pages/product/List.vue'
import ProductDetail from './pages/product/Product.vue'
import SearchResult from './pages/product/SearchResult.vue'
import Songle from './pages/product/Songle.vue'
import Yonglin from './pages/product/Yonglin.vue'
import Baoter from './pages/product/Baoter.vue'
import Index from './pages/Index'
import About from './pages/About'
import Contact from './pages/Contact'
// import ProductListItem from './components/product/ListItem'
// import Paginate from './components/common/Paginate'
// import Category from './components/product/Category'
// import Navigation from './components/common/Navigation'

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/:lang',
      component: Index
    },
    {
        path: '/:lang/products/categories/:category',
        component: ProductList
    },
    {
        path: '/:lang/products',
        component: ProductList,
    },
    {
        path: '/:lang/search',
        component: SearchResult
    },
    {
        path: '/:lang/products/:id',
        component: ProductDetail
    },
    {
        path: '/:lang/songle',
        component: Songle
    },
    {
        path: '/:lang/yonglin',
        component: Yonglin
    },
    {
        path: '/:lang/baoter/:id',
        component: Baoter
    },
    {
      path: '/:lang/about',
      component: About
    },
    {
      path: '/:lang/contact',
      component: Contact
    },
  ],
})
