<template>
    
    <div class="container pt-4">
        <div class="row">
            <img class="map-image" src="https://image.tyestore.com/FliBW6pwUw3ZAKEf1qLk63awgPvE" />
        </div>
        <div class="row py-4">
            <div class="col-md-6 col-sm-12 pt-3">
                <p class="text-left">{{$t('contact.address')}}</p>
                <p class="text-left">{{$t('contact.mobile')}}</p>
                <p class="text-left">{{$t('contact.wechat')}}</p>
                <p class="text-left">{{$t('contact.whatsapp')}}</p>
                <p class="text-left">{{$t('contact.emailaddress')}}</p>
            </div>
            <div class="col-md-6 col-sm-12">
                <form onsubmit="return false">
                    <h3>{{$t("contact.contactForm")}}</h3>
                    <div class="mb-3">
                        <input type="text" class="form-control" id="contactName" name="name" :placeholder="$t('contact.name')" />
                    </div>
                    <div class="mb-3 input-group">
                        <input type="text" class="form-control" id="contactEmail" name="email" :placeholder="$t('contact.email')" />
                        <input type="text" class="form-control" id="contactSubject" name="subject" :placeholder="$t('contact.subject')" />
                    </div>
                    <div class="mb-3">
                        <textarea class="form-control" name="note" id="contactNote" :placeholder="$t('contact.note')" />
                    </div>
                    <button class="btn btn-primary" v-on:click="contact" >{{$t('contact.send')}}</button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import mixin from '../common/mixin/mixin'

export default({
    name:"Contact",
    mixins: [mixin],
    methods:{
        contact(){
            const res = this.$post('contact', {
                "name": document.getElementById('contactName').value,
                "email": document.getElementById("contactEmail").value,
                "subject": document.getElementById("contactSubject").value,
                "note": document.getElementById("contactNote").value,
            });
            console.log(res);
            alert(this.$t("contact.success"))
        }
    }
})
</script>

<style>
    /* .map-image{
        height: 400px;
    } */
    .text-left{
        text-align: left;
    }
</style>