<template ref="home">
    <!-- <transition name="fade" mode="out-in"> -->
    <div >
        <Slider />
        <div class="container">
            <div class="row mt-4">
                <h3>{{$t("index.ourProducts")}}</h3>
                <Hotsale />
            </div>
        </div>
        <section class="page-section py-5" id="advantage">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8 text-center">
                        <h2 class="mt-0">{{$t("index.ourServices")}}</h2>
                        <hr class="divider my-2 m-auto">
                        <div class="row">
                            <div class="col-lg-3 col-md-6  col-6 text-center">
                                <div class="mt-5">
                                    <div class="order-icon icon fa-4x fa-gem text-primary mb-4 m-auto"></div>
                                    <h3 class="h4 mb-2">3000+</h3>
                                    <p class="text-muted mb-0">{{$t('index.customersServed')}}</p>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6  col-6 text-center">
                                <div class="mt-5">
                                    <div class="earth-icon icon fa-4x fa-gem text-primary mb-4 m-auto"></div>
                                    <h3 class="h4 mb-2">20+</h3>
                                    <p class="text-muted mb-0">{{$t('index.countriesExported')}}</p>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6  col-6 text-center">
                                <div class="mt-5">
                                    <div class="sku-icon icon fa-4x fa-gem text-primary mb-4 m-auto"></div>
                                    <h3 class="h4 mb-2">200+</h3>
                                    <p class="text-muted mb-0">{{$t("index.capSKUs")}}</p>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6  col-6 text-center">
                                <div class="mt-5">
                                    <div class="time-icon icon fa-4x fa-gem text-primary mb-4 m-auto"></div>
                                    <h3 class="h4 mb-2">21</h3>
                                    <p class="text-muted mb-0">{{$t("index.servedTime")}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="page-section py-md-5 pt-5" id="contact">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8 text-center">
                        <h2 class="mt-0">{{$t("index.getInTouch")}}</h2>
                        <hr class="divider my-2 m-auto">
                        <p class="text-muted mb-5">{{$t("index.touchDesc")}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="offset-md-2 col-md-4 col-6 ml-auto text-center mb-5 mb-md-0">
                        <div class="phone-icon icon fa-phone fa-3x mb-3 text-muted  m-auto"></div>
                            <div>+86 15652639411</div>
                        </div>
                        <div class="col-md-4  col-6 mr-auto text-center">
                            <div class="email-icon icon fa-phone fa-3x mb-3 text-muted  m-auto"></div>
                            <a class="d-block" href="mailto:info@tyestore.com">info@tyestore.com</a>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <!-- </transition> -->
</template>

<script>
    import Slider from '../components/common/Slider'
    import Hotsale from '../components/index/Hotsale'

    export default {
        name: 'home',
        data(){
            // console.log(this.$route.params.lang,this.$t)
            return {
                categories: {
                    "马达运行电容":["CBB60"],
                    "马达启动电容":["CD60"],
                    "空调电容":["CBB65"],
                    "风扇电容":["CBB61"],
                    // "Others":[
                    //     "陶瓷电容",
                    //     "钽电容A、B、C、D型",
                    //     "瓷片电容",
                    //     "独石电容",
                    //     "涤纶电容",
                    //     "电解电容",
                    //     "校正/安规电容",
                    //     "可调电容",
                    //     "固态电容/主板电容"
                    // ]
                }
            }
        },
        components:{Slider, Hotsale},       
    }
</script>

<style>
.divider {
    max-width: 3.25rem;
    border-width: .2rem;
    border-color: #6e98be;
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;
    border-style: inset;
    border-width: 1px;
}

#advantage{
    background-color:#f9f9f9;
}

.text-muted{
    color: #6c757d!important;
}
.order-icon{
    background-image:url("https://image.tyestore.com/FpRtvie4GEK6CM9jIEPoRAD5-ykI");
}
.sku-icon{
    background-image:url("https://image.tyestore.com/Fn0O3prCYJ4UHt1zSt29qe0ac-9U");
}
.time-icon{
    background-image:url("https://image.tyestore.com/FseH4h_m1d-BBxt245SmOsb4JumO");
}
.earth-icon{
    background-image:url("https://image.tyestore.com/FhV_NMmr6FAtU5WRmBSoxWTQosSs");
}
.phone-icon{
    background-image:url("https://image.tyestore.com/FvIoUvIyixqQ4c2sco6IlR7iYFoa");
}
.email-icon{
    background-image:url("https://image.tyestore.com/FiUkKzWACpEL02-HehWwMzil6NR0");
}
.icon{
    height:5em;
    width:5em;
    background-repeat:no-repeat; 
    background-size:100% 100%
}
</style>