import {createApp} from 'vue'
import * as vueI18n  from 'vue-i18n'
// import { createMetaManager, plugin as metaPlugin } from 'vue-meta'

import App from './App.vue'
import { router } from './router'
import 'bootstrap/dist/css/bootstrap.css'
import zh from './assets/i18n/zh.json'
import en from './assets/i18n/en.json'


const i18n = vueI18n.createI18n({
    locale: 'en', // set locale
    fallbackLocale: 'zh', // set fallback locale
    messages: {
        en: en,
        zh: zh
    },
  })

  

  
const app = createApp(App)
app.use(i18n).use(router)
// .use(createMetaManager()).use(metaPlugin)
app.mount('#app')
