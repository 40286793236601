<template>
    <div class="categories p-4 mb-3">
        <h4>{{$t('list.category')}}</h4>
        <div class="dropdown-divider"></div>
        <div class="category-block" v-for="(c, index) in categories" v-bind:key="index">
            <a class="category" @click='go(index)'>{{c}}</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Category",
        watch:{
            $route(to, from) {
                 if(to.params.lang != from.params.lang){
                    this.categories = this.getCategories()
                }
            }
        },
        data(){
            return {categories: this.getCategories()}
        },
        methods:{
            go(index) {
                this.$router.push({path:`/${this.$route.params.lang}/products/categories/${index}`}) 
            },
            getCategories(){
                return {
                    // "CBB":"CBB",
                    "CBB60":this.$t("category.CBB60"),
                    "CBB61":this.$t("category.CBB61"),
                    "CBB65":this.$t("category.CBB65"),
                    "CD60":this.$t("category.CD60"),
                    "CH85":this.$t("category.CH85")
                    // "ABS":"ABS", 
                    // "陶瓷电容":this.$t("category.taoci"), 
                    // "钽电容":this.$t("category.dan"),
                    // "瓷片电容":this.$t("category.cipian"), 
                    // "独石电容":this.$t("category.dushi"), 
                    // "涤纶电容":this.$t("category.dilun"), 
                    // "电解电容":this.$t("category.dianjie"), 
                    // "安规电容":this.$t("category.angui"), 
                    // "可调电容":this.$t("category.ketiao"), 
                    // "固态电容":this.$t("category.gutai")
                }
            }
        }
    }
</script>

<style>
    .categories {
        border: 1px solid #f3f3f3;
        text-align:left;
    }
    .category-block {
        padding:5px;
    }
    .category{
        font-size: 16px;
        font-weight: 400;
        text-decoration: none !important;
        color: #212529;

    }
</style>