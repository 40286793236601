<template>
    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleCaptions" v-for="(image,index) in images" v-bind:key="index" v-bind:class="{active:index==current}" :data-bs-slide-to="index" class="active" aria-current="true" aria-label="Slide 1"></button>
    </div>
    <div class="carousel-inner">
        <div v-for="(image,index) in images" v-bind:key="index" v-bind:class="{active:index==current}" class="carousel-item">
        <router-link :to="image.link">
            <img :src="image.src" class="d-block w-100" alt="...">
            <div class="carousel-caption d-none d-md-block">
                <h5>{{image.title}}</h5>
                <p>{{image.desc}}</p>
            </div>
        </router-link>
        </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
    </button>
    </div>
</template>
<script>
    import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'
    export default {
        name:"slider",
        data(){
            return {
                current:1,
                images:[
                    {
                        "src":"https://image.tyestore.com/ljdTNbY48k5IeDq87LTam9BwVHLF-wm5?imageView2/5/w/1800/h/1000",
                        "title":"空调、风扇电机启动、微波炉电容",
                        "desc":"",
                        "link":"/"+this.$route.params.lang+"/products"

                    },
                    {
                        "src":"https://image.tyestore.com/FnyJTcPYwtlPLKulERrL01XQOutj-wm5?imageView2/5/w/2250/h/1000",
                        "title":"CBB60螺丝地系列",
                        "desc":"马达启动电容",
                        "link":"/"+this.$route.params.lang+"/products/categories/CBB61"
                    },
                    {
                        "src":"https://image.tyestore.com/ljcSkfWHCXkMIx0-_0DhlNTN2kiH-wm5?imageView2/5/w/2250/h/1000",
                        "title":"CBB61带线系列",
                        "desc":"风扇启动电容",
                        "link":"/"+this.$route.params.lang+"/products/categories/CBB61"
                    },
                    {
                        "src":"https://image.tyestore.com/lhJ5AqLSYG5Ry_gkL_6XW8Y0Aswz-wm5?imageView2/5/w/2250/h/1000",
                        "title":"CBB65 TS系列",
                        "desc":"空调启动电容",
                        "link":"/"+this.$route.params.lang+"/products/categories/CBB65"
                    },
                    {
                        "src":"https://image.tyestore.com/lr228I4brT-J-c91QAsGYRi4rA20-wm5?imageView2/5/w/2250/h/1000",
                        "title":"CBB65 祥雪系列",
                        "desc":"空调启动电容",
                        "link":"/"+this.$route.params.lang+"/products/categories/CBB65"
                    },
                    {
                        "src":"https://image.tyestore.com/lqUANmY67Y58ycif4pHuxfaC0etq-wm5?imageView2/5/w/2250/h/1000",
                        "title":"CBB65系列",
                        "desc":"空调启动电容",
                        "link":"/"+this.$route.params.lang+"/products/categories/CBB65"
                    },
                    {
                        "src":"https://image.tyestore.com/lh1HFELm-FEXRSABgFfg7bMkki7H-wm5?imageView2/5/w/2250/h/1000",
                        "title":"CH85系列",
                        "desc":"微波炉电容",
                        "link":"/"+this.$route.params.lang+"/products/categories/CH85"
                    },
                ]
            }
        },
        mounted(){
            var myCarousel = document.querySelector('#carouselExampleCaptions')
            new bootstrap.Carousel(myCarousel)
        }
    }
</script>
<style>
    .carousel{
            background: linear-gradient(45deg,darkgray, transparent);
    }
</style>
