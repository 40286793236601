<template>
  <!-- <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | SITE_NAME` : `SITE_NAME` }}</template>
  </metainfo> -->
  <!-- <metainfo>
      <template v-slot:title="{ content }">{{ content }} - Yay!</template>
  </metainfo> -->
  <Header msg="泰源电子" />
  <router-view ></router-view>
  <Footer  />
</template>


<script>
import Header from './components/Header'
import Footer from './components/Footer'
import mixin from './common/mixin/mixin'
// import { useMeta,useActiveMeta } from 'vue-meta'


export default {
  mixins: [mixin],
  name: "App",
  mounted() {
    this.initCNZZ();
  },
  methods: {
      initCNZZ() {
          //添加脚本
          const script = document.createElement('script');
          script.src = 'https://s9.cnzz.com/z_stat.php?id=1280353101&web_id=1280353101';
          script.language = 'JavaScript';
          script.id='cnzz';
          document.body.appendChild(script);
      }
  },
  // setup () {
  //   useMeta({
  //     meta:[{
  //       name:"viewport",
  //     }
  //     ]
  //     // title: 'My Example App',
  //     // htmlAttrs: {
  //     //   lang: 'en',
  //     //   amp: true
  //     // }
  //   })
  //   const metainfo = useActiveMeta()
    
  //   return {
  //     metainfo
  //   }
  // },
  watch: {
      $route(to, from) {
        if (window._czc) {
            //监听路由变化
            let location = window.location;
            let contentUrl = location.pathname + location.hash;
            let refererUrl = '/';
            window._czc.push(['_trackPageview', contentUrl, refererUrl])
        }
        if(to.params.lang == undefined){
          var lang = window.navigator.language.slice(0, 2);
          if(lang != 'zh'){
            lang = 'en'
          }
          this.$router.push({ path: '/' + lang})
        }
        if(to.params.lang != this.$i18n.locale){
          this.$i18n.locale = to.params.lang
        }
        console.log(from)
      }
  },
  created(){
    // if(this.$route.params.lang == undefined){
    //     console.log(this.$route)
    //     // this.$router.push({ path: '/en'})
    // }
    // this.$i18n.locale = this.$route.params.lang
  },
  updated(){
    // console.log("updated");
    //  console.log(this.$route)
     if(this.$route.params.lang == undefined){
        // console.log(this.$route)
        this.$router.push({ path: '/en'})
    }
    this.$i18n.locale = this.$route.params.lang
  },
  components: {
    Header,
    Footer
  }
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
  /* margin-top: 60px; */
}
body {
    font-family: "Source Sans Pro";
    line-height: 30px;
    letter-spacing: 0px;
    font-weight: 400;
    font-style: normal;
    color: #212529;
    font-size: 17px;
    font-display: swap;
    overflow-x: hidden;
    width:100%;
}
a:link {color:#2c3e50;text-decoration: none;}    /* unvisited link */
a:visited {color:#2c3e50;text-decoration: none;} /* visited link */
a:hover {color:#2c3e50;text-decoration: none;}   /* mouse over link */
a:active {color:#2c3e50;text-decoration: none;}  /* selected link */
</style>
