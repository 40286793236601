const axios = require('axios').default;

const instance = axios.create({
    baseURL: 'https://api.tyestore.com',
  });

export const request = (module, data={}) => {
    return new Promise((resolve, reject) => {
        instance.get('/'+module, {
            params: data
          })
          .then(function (response) {
            // console.log(response);
            resolve(response)
          })
          .catch(function (error) {
            // console.log(error);
            reject(error)
          })
    })
}

export const post = (module, data={}) => {
  return new Promise((resolve, reject) => {
      instance.post('/'+module, data)
        .then(function (response) {
          // console.log(response);
          resolve(response)
        })
        .catch(function (error) {
          // console.log(error);
          reject(error)
        })
  })
}