import {request, post} from '@/common/js/request'

export default {
    data() {
        return {
            page:0,
            pageNum: 10,
            loadingType: 1, 
            isLoading: false,
            loaded:false,
        }
    },
    created() {
        // this.log("xxx")
    },
    methods: {
        log(data) {
            console.log(JSON.parse(JSON.stringify(data)))
        },
        $request(module, data={}){
            return new Promise((resolve, reject) => {
                request(module, data).then(result=>{
                    // setTimeout(()=>{
                    //     if(this.setLoaded !== false){
                    //         this.loaded = true;
                    //     }
                    // }, 100)
                    // this.$refs.confirmBtn && this.$refs.confirmBtn.stop();
                    resolve(result);
                }).catch((err) => {
                    reject(err);
                })
            })
        },
        $post(module, data={}){
            return new Promise((resolve, reject) => {
                post(module, data).then(result=>{
                    resolve(result);
                }).catch((err) => {
                    reject(err);
                })
            })
        }
    }
}