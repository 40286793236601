<template>
    <div v-if="category || productName " id="navigation">
        <span>
            <router-link class="navLink" :to="`/${lang}`">{{$t('header.index')}}</router-link> >>
        </span>
        <span v-if="category!=''" >
            <router-link class="navLink" :to="`/${lang}/products`">{{$t('list.category')}}</router-link> >> 
        </span>
        <span v-if="category!=''" >
            <router-link class="navLink" :to="`/${lang}/products/categories/${category}`">{{category}}</router-link> >>
        </span>
        <span class="navLink">{{productName}}</span>
    </div>
</template>
<script>
export default {
    name: "Navigation",
    data() {
        return {
            lang: ''
        } 
    },
    props: {
        category: String,
        productName: String
    },
    created(){
        this.lang = this.$route.params.lang
    }
}
</script>
<style scoped>
    #navigation{
        text-align: left;
    }
    .navLink {
        text-decoration: none;
        color: #a0a0a0;
    }
</style>