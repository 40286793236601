<template>
    <div class="container pt-4">
        <h1 >{{$t('about.aboutus')}}</h1>
        <div class="row">
             <div class="col-md-5">
                <div class="row">
                    <figure class="figure">
                        <img src="https://image.tyestore.com/FrOyTYNYDxClhIfCJycMpOKnNlQD" class="figure-img img-fluid rounded" >
                        <figcaption class="figure-caption">{{$t('about.saleArea')}}</figcaption>
                    </figure>
                    
                </div>
            </div>
            <div class="col-md-7 ">
                <div class="row">
                    <p class="desc">
                        {{$t('about.desc')}}
                    </p>
                </div>
                <div  class="row">
                    <h4 class="text-left">{{$t('about.product_type')}}</h4>
                    <p class="desc">
                        {{$t('about.product_type_desc')}}
                    </p>
                </div>
                <div  class="row">
                    <h4 class="text-left">{{$t('about.customer')}}</h4>
                    <p class="desc">
                        {{$t('about.customer_desc')}}
                    </p>
                </div>
            </div>
            <div class="row">
                <p class="desc">
                    {{$t('about.desc1')}}
                </p>
                <p class="desc">
                     {{$t('about.desc2')}}
                </p>
            </div>
        </div>
    </div>
</template>
<style>
    .desc {
        text-align:left;
        line-height: revert;
        color: #6e747c;
    }
</style>