<template>
    <div class="container">
        <div class="row px-5">
            <b class="search-title"><span class="search-product">{{this.$route.query.s}}</span> {{$t('search.relatedProducts')}}</b>
        </div>
        <div class="row my-5">
            <div v-if="isLoading==true" class="d-flex justify-content-center">
                <div class="spinner-border m-5" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
            <h3 v-if="isLoading== false && products.length==0">{{$t('search.notFound')}}</h3>
            <div class="col-4 list-item" v-for="product in products" :key="product.id" >
                <ProductListItem :product="product" />
            </div>
        </div>
    </div>
</template>
<script>
import ProductListItem from '../../components/product/ListItem'

import mixin from '../../common/mixin/mixin'

export default {
    name: 'SearchResult',
    mixins: [mixin],
    data() {
        return {
            isLoading:true,
            s: "",
            products: []
        }
    },
    created(){
        // alert("created")
        // console.log(this.$route.query.category)

        if(this.$route.query.s != undefined){
            this.s = this.$route.query.s
        }
        // console.log("created load data")
        this.loadData(); //加载评价
        this.$watch('s', ()=>{
        },{deep: true})
    },
    updated(){
        // alert("updated")
        // console.log(this.$route.query)
        // console.log(this.$route.params.category)
        console.log(this.s , this.$route.query.s)
        var isUpdate = false
        if(this.s != this.$route.query.s){
            this.s = this.$route.query.s
            isUpdate = true
        }
        if(isUpdate == true){
            // console.log("updated load data")
            this.loadData();
        }
        
        
    },
    components: {
        ProductListItem,
    },
    props: {
        list: Array
    },
    methods: {
        async loadData(){
            // console.log(this)
            this.isLoading = true
            var page = this.$route.query.page != undefined ? this.$route.query.page : 1
            var query = {
                page: page,
                pageSize:100,
            }
            if(this.s != undefined){
                query["name"] = this.s
            }
            // console.log(query)
            const res = await this.$request('store/products', query)
            // console.log(res.data.data)
            // if(res.data.data.list.length === 0){
            //     return;
            // }

            const data = res.data.data.list;
            for(var i = 0 ; i < data.length; i++) {
                if(data[i]["coverImage"] != undefined) {
                    data[i]["coverImage"] = "https://image.tyestore.com/"+data[i]["coverImage"]
                }else {
                    data[i]["coverImage"] = "https://image.tyestore.com/FvyFkzEnbB3j7Xf9uO7rOUAKaGEj"
                }
            }
            this.products = data;
            this.isLoading = false;
            // this.totalPage = parseInt(res.data.data.total / 10) + 1;
            // this.currentPage = res.data.data.currentPage;
            // this.$forceUpdate(()=>{

            // })
            // this.$nextTick(()=>{
            //     // this.calcAnchor();//计算锚点参数
            // })
            //添加浏览历史
            // this.addProductHistory();
        },
    }
}
</script>

<style >
    /* .primary {
        padding-bottom: 20px;
        padding-left:50px;
    } */
    .search-title {
        text-align: left;
    }
    .search-product {
        font-size:30px;
        font-weight: 600;
        color:#60a9c5;
    }
</style>
