<template>
    <div class="row" v-if="list != undefined && list.length > 14">
        <div class="col-md-6">
            <table class="table table-striped  table-bordered border-dark">
                <thead>
                    <tr class="table_header border-dark">
                        <th scope="col">{{$t("detail.voltUnit")}}</th>
                        <th scope="col">{{$t("detail.capacitanceUnit")}}</th>
                        <th scope="col">{{getSizeTitle()}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="size, index in list.slice(0,list.length/2)" v-bind:key="index">
                        <th scope="">{{size.volt}}</th>
                        <th scope="row">{{size.cap}}</th>
                        <td>{{size.size}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-6">
            <table class="table table-striped  table-bordered border-dark">
                <thead>
                    <tr class="table_header border-dark">
                        <th scope="col">{{$t("detail.voltUnit")}}</th>
                        <th scope="col">{{$t("detail.capacitanceUnit")}}</th>
                        <th scope="col">{{getSizeTitle()}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="size, index in list.slice(list.length/2, list.length)" v-bind:key="index">
                        <th scope="">{{size.volt}}</th>
                        <th scope="row">{{size.cap}}</th>
                        <td>{{size.size}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    
    <div class="row" v-if="list != undefined && list.length <= 14">
        <div class="col-md-12">
            <table class="table table-striped  table-bordered border-dark">
                <thead>
                    <tr class="table_header border-dark">
                        <th scope="col">{{$t("detail.voltUnit")}}</th>
                        <th scope="col">{{$t("detail.capacitanceUnit")}}</th>
                        <th scope="col">{{getSizeTitle()}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="size, index in list" v-bind:key="index">
                        <th scope="">{{size.volt}}</th>
                        <th scope="row">{{size.cap}}</th>
                        <td>{{size.size}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</template>
<script>
export default ({
    name:'table',
    props:{
        list: Array
    },
    methods:{
        getSizeTitle(){
            return this.list[0]["size"].split("*").length == 3 ? this.$t("detail.lwh"):this.$t("detail.dh")
        }
    }
})
</script>
