<template ref="product">
    <div id="detail">
        <div class="container pt-4">
            <div class="row px-md-5 mb-3">
                <Navigation :productName="curProduct.name" :category="curProduct.category"  />
            </div>
            <div class="row product-title px-md-5" >
                <h3>{{curProduct.name}}</h3>
                <p >{{$t("detail."+aggrProduct.note)}}</p>
            </div>
            
            <div class="row px-md-5 pb-3">
                <div class="col-md-4 col-sm-12 px-0 mb-3">
                    <ProductImagesGallery :threeDFile="curProduct['3d']" :images="curProduct['images']" />
                </div>
                <div class="col-md-8  col-sm-12 text-left px-4" >
                    <div class="card p-3">
                        <div class="row ">
                            <div v-for="(attrs, index) in aggrProduct.skus.attributes" v-bind:key="index">
                                <b>{{$t(index)}}</b>
                                <ul>
                                    <a @click="switchProduct($t(attr), index)" v-for="(attr, i) in attrs" v-bind:key="i">
                                        <li v-bind:class="attrClass($t(attr),index)" class="attr" >
                                            {{$t(attr)}}
                                        </li>
                                    </a>
                                </ul>
                            </div>
                        </div>
                        <div class="row"> 
                            <h3>{{$t("detail.features")}}</h3>
                            <div class="dropdown-divider"></div>
                            <p v-html="$t(aggrProduct.description)"></p>
                        </div>
                    </div>
                </div>
            </div>

            

            <div class="row px-md-5">
                <div class="col-md-3 col-xs-12 col-sm-12">
                    <Category />
                </div>
                <div class="col-md-9 col-xs-12 col-sm-12">
                    <div class="row">
                        <img class="serialsImage" :src="`${serialsImage}-wm5?imageView2/0/w/1200/h/800/q/100/interlace/1`" />
                    </div>
                    <!-- <div class="row">
                        <img class="detailImage" :src="`${curProduct.coverImage}?imageView2/0/w/1200/h/800/q/100/interlace/1`" />
                    </div> -->
                    
                    <!-- <div class="row"> -->
                    <!-- </div> -->

                    
                    <div class="m-4">
                    <p class="subtitle">{{$t("detail.desc")}}</p>
                    </div>
                    <p class="descInfo" v-html="curProduct.desc"></p>
                    <div class="m-4">
                    <p class="subtitle">{{$t("detail.usage")}}</p>
                    </div>
                    <p class="descInfo" v-html="curProduct.usage"></p>
                    <div class="m-4">
                    <p class="subtitle">{{$t("detail.tech")}}</p>
                    </div>
                    <p class="descInfo" v-html="curProduct.tech"></p>

                    <div class="m-4">
                    <p class="subtitle">{{$t("detail.detailImage")}}</p>
                    </div>
                    <img class="col-12 col-md-4 p-1 detailImages" v-for="(image, index) in curProduct.images" v-bind:key="index" :src="`${image}-wm5?imageView2/1/w/300/h/300/q/100/interlace/1`" />


                    <div class="m-4">
                        <p class="subtitle">{{$t("detail.dimensions")}}</p>
                    </div>
                    <div class="row">
                        <img class="dimensionImage" :src="`${dimensionsImage}-wm5`" />
                    </div>
                    <div class="row" v-if="curProduct.acMapping != undefined">
                        <table class="table table-striped  table-bordered border-dark caption-top ">
                            <caption>{{$t("detail.cbb65decicion")}}</caption>
                            <thead>
                                <tr class="table_header border-dark">
                                    <th v-for="item,i in curProduct.acMappingTitle" v-bind:key="i"  scope="col">{{item}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="u, index in curProduct.acMapping" v-bind:key="index">
                                    <th v-for="item,i in u" v-bind:key="i" scope="">{{item}}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- 尺寸 -->
                    
                    <div class="m-4">
                        <p class="subtitle">{{$t("detail.usualDimensions")}}</p>
                    </div>
                    <Table :list="curProduct.sizes" />


                    <div class="m-4">
                        <p class="subtitle">{{$t("detail.interestedProducts")}}</p>
                    </div>
                    <div class="row pt-2">
                        <!-- <div class="row pt-4"> -->
                            <div class="col-md-3 col-6" v-for="product in relatedProducts" :key="product.id" >
                                <ProductListItem :product="product" />
                            </div>
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import mixin from '../../common/mixin/mixin'
import Navigation from '../../components/common/Navigation'
import ProductListItem from '../../components/product/ListItem'
import ProductImagesGallery from '../../components/product/ProductImagesGallery'
import ProductsInfo from '../../assets/products/baoter.json'
import ProductDetailImages from '../../assets/products/detailImages.json'
import Category from '../../components/product/Category'
import Table from '../../components/product/Table'
import Baoter from '../../assets/products/baoter.json'


export default {
    name: 'Product',
    // inject:["reload"],
    data() {
        return {
            curProduct: {},
            curCoverImage:"",
            serialsImage: "",
            dimensionsImage:"",
            threeDWidth:0,
            aggrProduct: {skus:{attributes:{}}},
            mappingProducts:{},
            mappingAttrs:{},
            relatedProducts: []
        }
    },
    watch: {
        $route(to, from) {
            this.loadData();
            console.log(to, from);
        }
    },
    
   
    // components: {Navigation,ProductListItem,Category},
    components: {Navigation,ProductListItem,Category,Table, ProductImagesGallery},
    created(){
        this.loadData();
        
        this.$watch('aggrProduct', ()=>{
        },{immediate: true})
        this.$watch('threeDWidth', ()=>{
        },{immediate:true})
        this.$watch('curProduct', ()=>{
        }, {immediate:true})
        this.$watch('mappingProducts', ()=>{
        }, {immediate:true})
        this.$watch('mappingAttrs', ()=>{
        }, {immediate:true})
        this.$watch('relatedProducts', ()=>{
        }, {immediate:true})
        
    },
    updated(){
        // console.log(this.$route.params.id, this.curProduct.id)
        // if(this.$route.params.id != this.curProduct.id){
        //     this.loadData()
        // }
        
    },
    mixins: [mixin],
    props: {
        product: Object,
    },
    methods: {
        async loadData(){
            // console.log(this.$route)
            // const res = await this.$request('store/products/'+this.$route.params.id, '', {
                
            // })
            // if(res.data.length === 0){
            //     this.$util.msg(res.msg || '产品不存在或已下架');
                
            //     return;
            // }

            // const data = res.data;
            const data = Baoter[this.$route.params.id]
            if(data["coverImage"] != undefined) {
                data["coverImage"] = "https://image.tyestore.com/"+data["coverImage"]
            }else {
                data["coverImage"] = "https://image.tyestore.com/FvyFkzEnbB3j7Xf9uO7rOUAKaGEj"
            }
            this.curProduct = this.bindImageData(data);
            this.bindCommonData();
            this.curCoverImage = data["coverImage"]

            if(ProductsInfo[data["type"].trim(" ").toUpperCase()] != undefined){
                // console.log(ProductsInfo[data["category"].trim().toUpperCase()])
                this.aggrProduct = ProductsInfo[data["type"].trim().toUpperCase()]
            }
            this.loadRelatedProducts()
        },
        bindCommonData(){
            var infos = ProductsInfo[this.curProduct["type"]]
            // var acMapping = infos['airConditionMapping']
            // var usageTitle = this.$t('cbb65.tableHeader')
            // if(acMapping != undefined){
            //     var ret = []
            //     acMapping.forEach((u) => {
            //         ret.push(u.split(","))
            //     })
            //     this.curProduct["acMapping"] = ret
            //     this.curProduct["acMappingTitle"] = usageTitle.split(",")
            //     // console.log(this.curProduct["usage"])
            // }
            this.curProduct["tech"] = this.$t(infos["tech"])
            this.curProduct["desc"] = this.$t(infos["desc"])
            this.curProduct["usage"] = this.$t(infos["usage"])
        },
        bindImageData(data) {
            var categoryImages = ProductDetailImages[data["category"]]
            var type = data["type"]
            // console.log("bindImage", type,categoryImages,categoryImages[type]["images"])
            this.serialsImage = categoryImages[type]["serialsImage"];
            this.dimensionsImage = categoryImages[type]["dimensionsImage"];
            data["images"] = [data["coverImage"]].concat(categoryImages[type]["images"])
            data["detailsImages"] = categoryImages[type]["images"]
            data["3d"] = categoryImages[type]["3d"]
            data["sizes"] = this.filterSizeData(categoryImages[type]["sizes"])
            // data["sizesTitle"] = data["sizes"][0]["size"].split("*").length == 3 ? this.$t("detail.lwh"):this.$t("detail.dh")
            return data
        }, 
        filterSizeData(data){
            var ret = []
            data.forEach((size)=>{
                var s = size.split(":")
                ret.push({volt:s[0], cap: s[1], size:s[2]})
            })
            return ret
        },
        async loadRelatedProducts() {
            var query = {
                "category": this.curProduct.category.trim(" "),
                "pageSize":9999,
                }
            const res = await this.$request('store/products', query);
            const data = res.data.data.list;
            this.mapProducts(data)
            var ret = []
            for(var i = 0 ; i < data.length; i++) {
                if(data[i]['id'] == this.curProduct.id){
                    continue
                }
                if(data[i]["coverImage"] != undefined) {
                    data[i]["coverImage"] = "https://image.tyestore.com/"+data[i]["coverImage"]
                }else {
                    data[i]["coverImage"] = "https://image.tyestore.com/FvyFkzEnbB3j7Xf9uO7rOUAKaGEj"
                }
                ret.push(data[i])
                if(ret.length == 8) {
                    break;
                }
            }
            this.relatedProducts = ret;
        },
        mapProducts(products){
            var category = this.curProduct.category.trim(" ").toUpperCase()
            for(var i = 0; i < products.length; i++){
                var p = products[i]
                
                
                var reg = RegExp(ProductsInfo[category]["titleRegex"], "i")

                var name = p["name"].toUpperCase().trim()
                // console.log(reg, name, reg.test(name) )
                if(!reg.test(name)){
                    continue
                }
                name = name.replace("盛进", "")
                name = name.replace(category, "")
                name = name.replace(" ", "")
                var arr = name.split("UF")
                // console.log(arr)
                
                var typeMapping = {
                    "CBB65":{
                        "带螺丝底":this.$t("withScrew"),
                        "":this.$t("withoutScrew")
                    },
                    "CBB61":{
                        "线":this.$t("twoWires"),
                        "二插片":this.$t("twoPins"),
                        "四插片":this.$t("fourPins"),
                        "针脚": this.$t("twoNeedles"),
                        "带螺丝底":this.$t("fourPinsWithScrew"),
                    },
                    "CBB60":{
                        "带线":this.$t("twoWires"),
                        "四插片":this.$t("fourPins"),
                        "带螺丝底":this.$t("fourPinsWithScrew"),
                    },
                    "CD60":{
                        "带线":this.$t("twoWires"),
                        "": this.$t("fourPins")
                    },
                    "CD60 330V":{
                        "": this.$t("fourPins")
                    },
                    "CH85":{
                        "": this.$t("fourPins")
                    }
                }
                var cap = this.getCapacitanceByName(name)
                var t = typeMapping[category] == undefined || typeMapping[category][arr[1].trim(" ")] == undefined ? arr[1].trim(" ") : typeMapping[category][arr[1].trim(" ")]
                this.mappingProducts[p["id"]] = {
                    volt:'450V',
                    capacitance:cap,
                    leadingWay: t,
                    name: p["name"].toUpperCase().trim(),
                }
                if(category == "CD60 330V"){
                    this.mappingProducts[p["id"]]["volt"] = '330V'
                }
                if(category == "CH85"){
                    this.mappingProducts[p["id"]]["volt"] = '2100V'
                    this.mappingProducts[p["id"]]["leadingWay"] = this.$t("fourPins")
                }
                var volt = this.mappingProducts[p["id"]]["volt"]
                if(this.mappingAttrs[t] == undefined){
                    this.mappingAttrs[t] = [p["id"]]
                }else {
                    this.mappingAttrs[t].push(p["id"])
                }
                if(this.mappingAttrs[cap] == undefined){
                    this.mappingAttrs[cap] = [p["id"]]
                }else {
                    this.mappingAttrs[cap].push(p["id"])
                }
                if(this.mappingAttrs[volt] == undefined){
                    this.mappingAttrs[volt] = [p["id"]]
                }else {
                    this.mappingAttrs[volt].push(p["id"])
                }
            }
            // console.log(this.mappingAttrs)
        },
        getCapacitanceByName(name){
            var arr = name.split(" ")
            var ret = ""
            arr.forEach((item)=>{
                if(item.indexOf("UF") != -1){
                    ret = item.replace("UF", "μF")
                }
            })
            return ret
        },
        switchProduct(attr, index) {
            var pid = this.getProductId(attr,index)
            if (pid != ""){
                this.$router.push({path:`/${this.$route.params.lang}/products/${pid}`}) 
                // this.loadData()
                // this.reload();·····
            }
            return 
        },
        getProductId(attr, index) {
            var possibleIds = this.mappingAttrs[attr]
           
            if(possibleIds == undefined){
                return ""
            }
            var curProductAttrs = this.mappingProducts[this.curProduct['id']]
            var pid = ""

            var category = this.curProduct.category.toUpperCase().trim(" ")
            var nameArr = this.curProduct.name.split(" ")
            // console.log(attr, nameArr)
            if(!(category == "CD60 330V" || category == "CBB65") && nameArr[2] == undefined){
                return ""
            }
            var isSameSerials = true
            if(curProductAttrs[index] != attr){
                isSameSerials = false
            }
            // console.log(nameArr)
            // console.log("sameSerials", isSameSerials)
            
            var reg = RegExp(ProductsInfo[category]["titleRegex"] +` `+ nameArr[2], "i")
            if(category == "CD60 330V" || category == "CBB65" || category == "CH85"){
                reg = RegExp(ProductsInfo[category]["titleRegex"], "i")
            }
            for(var i = 0; i < possibleIds.length; i++){
                var productId = possibleIds[i]
                var selectedProductAttrs = this.mappingProducts[productId]
                var isEqual = true

                var name = this.mappingProducts[productId].name
                // console.log(name)
                if(isSameSerials && !reg.test(name.trim())){
                    // console.log(isSameSerials, reg, name.trim(), reg.test(name.trim()))
                    continue
                }
                // console.log(selectedProductAttrs, attr,index)
                Object.keys(selectedProductAttrs).forEach((key)=>{
                    if(key == index || key == "name") {
                        // console.log(key, index)
                        return ""
                    }
                  
                    if(selectedProductAttrs[key] != curProductAttrs[key]){
                        isEqual = false
                    }
                })
                if(isEqual == true){
                    pid = productId
                    return pid
                }
            }
            return ""
        },
        //每个属性都会循环，每个attr, 例如uf，和插口，需要某个属性与对应的值有没有，例如cap和15uf能不能卖，map[capIndex(capacitor)][attr(15uf)]!=nil
        attrClass(attr, index){
            var c = []
            var mp = this.mappingProducts[this.curProduct['id']]
            // console.log(attr, index, mp)
            if(mp != undefined && attr==mp[index]) {
                c.push('choosedAttr');
                return c.join(" ")
            }
            if(mp == undefined){
                return ""
            }
            if(this.getProductId(attr, index) == ""){
                c.push("soldOutAttr")
            }
            return c.join(" ")
        },
        
    }
}
</script>

<style>
.product-title{
    text-align:left;
    /* padding:10px 0px; */
}
.curCoverImage{
    border:2px solid black;
}
.coverImage{
    width:100%;
}
.detailImgs {
    margin:10px; 
    width: 50px;
}
.thumbs {
    padding:0px;
}

.attr {
    border:2px solid rgba(0, 0, 0, 0.2);
    margin: 4px 3px;
    padding:0px 8px;
}
.choosedAttr{
    border:2px solid red;

}
.soldOutAttr{
    color: rgba(0,0,0,.15);
    border:2px dotted rgba(0, 0, 0, 0.15);
}
.detailImages{
    margin-right:5px;
}
.serialsImage,.dimensionImage{
    height:100%;
}
.table_header{
    --bs-table-bg: #3f6fbc;
    color: #fff;
}
.subtitle{
    border-bottom: 2px solid #b12953;
    border-width: thick;
    /* width: 80%; */
    border-block-start: initial;
    font-size:34px;
    font-weight: 500;
    color:black;
    display:inline;
}
.descInfo{
    text-align:left;
    font-size:20px;
    font-weight: 500;
    margin:20px 5%;
}
</style>