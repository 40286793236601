<template>
    <div id="3d" class="threeD"></div>
</template>
<script>
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import * as THREE from 'three'
var scene,controls;
export default({
    name:"3d",
    data(){
        return {
            camera: null,
            renderer: null,
            // mesh: null,
            loader:null,
        }
    },
    props: {
        threeDFile: String, 
    },
    created(){
        this.$watch('threeDFile', ()=>{
        },{immediate:true})
    },
    methods:{
        init(){
            window.addEventListener( 'resize', this.onWindowResize, false );
            scene = new THREE.Scene();
            // const light = new THREE.AmbientLight( 0xffffff ); // soft white light
            // scene.add( light );
            const bgcolor = new THREE.Color(0xf6f6f6)
            scene.background = bgcolor
            this.camera = new THREE.PerspectiveCamera( 45, 1, 1, 1000 );
            this.renderer = new THREE.WebGLRenderer({
                antialias:true,
                alpha:false
            });
            var width = document.getElementsByClassName('coverImageRow')[0].offsetWidth
            this.renderer.context.disable(this.renderer.context.DEPTH_TEST);
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.renderer.setSize( width, width );
            
            document.getElementById("3d").appendChild( this.renderer.domElement );
   
            const light1 = new THREE.HemisphereLight( 0xffffff, 0x080820, 1 );
            scene.add( light1 );

            this.loader = new GLTFLoader();
            this.loader.setPath( "/static/3d/" );

            this.loader.load(this.threeDFile, ( object ) => {
//                 object.scene.traverse( function ( child ) {
// if ( child.isMesh ) {
//     child.material.emissive =  child.material.color;
// 	child.material.emissiveMap = child.material.map ;
// }
// } );
                // object.scene.castShadow = true; //default is false
                // object.scene.receiveShadow = false; //default
                const directionalLight = new THREE.DirectionalLight( 0xffffff, 1 );
                directionalLight.position.set(0, 0, 10000);
                scene.add( directionalLight );
               
                directionalLight.target = object.scene
                // object.scene.material.opacity(0.5);
                scene.add(object.scene);


                //
                // var bBox = new THREE.Box3().setFromObject(scene);
                // var height = bBox.size().y;
                var dist = 200 / (2 * Math.tan(this.camera.fov * Math.PI / 360));
                var pos = object.scene.position;
                this.camera.position.set(pos.x, pos.y, dist * 1.1); // fudge factor so you can see the boundaries
                this.camera.lookAt(pos);






                }, function ( xhr ) {
                    console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
                },
                function ( error ) {
                    console.log( error );
            });
            controls = new OrbitControls(this.camera, this.renderer.domElement );
            this.camera.position.set(-20, 20, 75);
            
            controls.target.set(0, 0, 0);

            controls.enablePan = false;
            controls.maxPolarAngle = Math.PI / 2;

            controls.enableDamping = true;
            // controls.update();

           
        },

        
        
        animate(){
            requestAnimationFrame( this.animate );
            controls.update();
            this.renderer.render( scene, this.camera );
        },

        onWindowResize(){
            var width = document.getElementsByClassName('coverImageRow')[0].offsetWidth
            this.renderer.setSize( width, width );
        }
    },
    mounted(){
        this.init();
        this.animate();
    }
})
</script>
<style scoped >
    .threeD{
        width:80%;
    }
</style>